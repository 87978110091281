import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import classNames from 'classnames';
import { Container, Row, Column } from '../Grid';
import Image from '../Image';
import Section from '../Section';
import './styles.scss';

/**
 * A global hero component
 */

const Hero = ({ title, image, isHome }) => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          programName
          programLogo
        }
      }
    }
  `);

  return (
    <Section className={classNames('hero', isHome && 'hero--home')}>
      <div
        className="hero__img"
        style={{ backgroundImage: `url("${image}")` }}
      />
      <Container fullWidth>
        <Row>
          <Column xsmall={6} small={isHome ? 5 : 6} medium={5}>
            {isHome && (
              <Image
                filename={site.siteMetadata.programLogo}
                alt={site.siteMetadata.programName}
                className="hero__logo"
              />
            )}
            <h1 className="hero__heading">{title}</h1>
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

Hero.propTypes = {
  title: PropTypes.node.isRequired,
  image: PropTypes.string.isRequired,
  isHome: PropTypes.bool,
};

export default Hero;
