import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import Hero from '../components/Hero';
import Image from '../components/Image';
import Button from '../components/Button';
import { useTranslation } from 'react-i18next';
import HeroImage from '../images/hero/hero-career-profiles.png';

const CareerProfilesPage = ({ data }) => {
  const profiles = data.allCareerProfilesYaml.edges.map((edge) => edge.node);
  const { t } = useTranslation(['career-profiles', 'common']);

  return (
    <Layout title="Career Profiles">
      <Hero title={t('common:careerProfiles')} image={HeroImage} />
      <Section>
        <Container>
          <Row>
            <Column size={8}>
              <h2>{t('introHeader')}</h2>
              <p>{t('introBody')}</p>
            </Column>
          </Row>
          <Row>
            {profiles.map((profile, i) => (
              <Column size={4} key={i}>
                <div className="career-profiles__profile">
                  <Link to={`/career-profiles/${profile.parent.name}`}>
                    <Image filename={t(profile.relatedImage)} />
                  </Link>
                  <h2 className="career-profiles__profile-headings">
                    {t(profile.title)}
                  </h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: t(profile.shortDescription),
                    }}
                  />
                  <Button to={`/career-profiles/${t(profile.parent.name)}`}>
                    {t('common:footerPartnerButtonText')}
                  </Button>
                </div>
              </Column>
            ))}
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default CareerProfilesPage;

export const query = graphql`
  query CareerProfilesQuery {
    allCareerProfilesYaml(
      sort: { fields: [sortIndex, title], order: [ASC, ASC] }
    ) {
      edges {
        node {
          title
          sortIndex
          shortDescription
          relatedImage
          parent {
            ... on File {
              name
            }
          }
        }
      }
    }
  }
`;
